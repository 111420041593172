export default Object.freeze({
	general: {
		client_name: '',
		client_surname: '',
		client_last_surname: '',
		primary_phone: '',
		secondary_phone: '',
		email: '',
		comments: '',
	},
	billing_information: {
		business_name: '',
		rfc: '',
		type: '',
		street_name: '',
		external_number: '',
		internal_number: '',
		neighborhood: '',
		locality: '',
		state: '',
		zip_code: '',
		email: '',
		alternate_email: '',
	},
})
