export const adminsList = [
	{
		id: 1,
		name: 'Ismael',
	},
	{
		id: 11,
		name: 'Pozos',
	},
	{
		id: 12,
		name: 'Fabián',
	},
]
