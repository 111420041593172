var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-spin',{attrs:{"tip":_vm.spinnerLoaderLabel,"size":"large","spinning":_vm.spinnerLoader}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('a-form-item',{attrs:{"label":"Monto de descuento"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'amount',
							{
								rules: [
									{
										required: true,
										message: 'Ingresa monto del ticket',
									},
								],
							},
						]),expression:"[\n\t\t\t\t\t\t\t'amount',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\tmessage: 'Ingresa monto del ticket',\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}],attrs:{"autocomplete":"off","type":"number","prefix":"$"}})],1)],1)]),_c('a-button',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"text-center",attrs:{"size":"large","type":"primary","htmlType":"submit","id":"discountComponent_submit"}},[_c('strong',[_vm._v("Guardar")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }