var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{ref:"clientsDetailGeneralForm",staticClass:"mb-4",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('a-form-item',{staticClass:"m0",attrs:{"label":"Nombre(s)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'client_name',
							{
								rules: [
									{
										required: true,
										message: 'Campo requerido.',
									},
								],
							},
						]),expression:"[\n\t\t\t\t\t\t\t'client_name',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\tmessage: 'Campo requerido.',\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}],attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('a-form-item',{staticClass:"m0",attrs:{"label":"Primer apellido"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'client_surname',
							{
								rules: [
									{
										required: true,
										message: 'Campo requerido.',
									},
								],
							},
						]),expression:"[\n\t\t\t\t\t\t\t'client_surname',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\tmessage: 'Campo requerido.',\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}],attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('a-form-item',{staticClass:"m0",attrs:{"label":"Segundo apellido"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['client_last_surname']),expression:"['client_last_surname']"}],attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('a-form-item',{staticClass:"m0",attrs:{"label":"Teléfono principal"}},[_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('##########'),expression:"'##########'"},{name:"decorator",rawName:"v-decorator",value:([
							'primary_phone',
							{
								rules: [
									{
										required: true,
										message: 'Campo requerido.',
									},
								],
							},
						]),expression:"[\n\t\t\t\t\t\t\t'primary_phone',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\tmessage: 'Campo requerido.',\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}],attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('a-form-item',{staticClass:"m0",attrs:{"label":"Teléfono secundario"}},[_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('##########'),expression:"'##########'"},{name:"decorator",rawName:"v-decorator",value:(['secondary_phone']),expression:"['secondary_phone']"}],attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('a-form-item',{staticClass:"m0",attrs:{"label":"Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'email',
							{
								rules: [
									{
										email: true,
										message: 'Ingresa email válido',
									},
								],
							},
						]),expression:"[\n\t\t\t\t\t\t\t'email',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\temail: true,\n\t\t\t\t\t\t\t\t\t\tmessage: 'Ingresa email válido',\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}],attrs:{"autocomplete":"off"}})],1)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showControls),expression:"showControls"}],staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('hr',{staticClass:"hrText"})]),_c('div',{staticClass:"col-md-4 text-left"},[_c('a-button',{staticClass:"btn btn-warning",attrs:{"icon":"arrow-left"},on:{"click":_vm.onCancel}},[_vm._v("Regresar")])],1),_c('div',{staticClass:"col-md-8 text-right"},[_c('a-button',{staticClass:"btn btn-success",attrs:{"icon":"save","htmlType":"submit","id":"clientDetailGeneralComponent_submit"}},[_vm._v("Guardar")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }