<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<posComponent />
		</a-spin>
	</div>
</template>

<script>
import posComponent from '@/components/pos'

export default {
	name: 'posView',
	components: {
		posComponent,
	},
	computed: {
		spinnerLoaderLabel() {
			return this.$store.state.pos.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.pos.spinnerLoader
		},
	},
}
</script>
