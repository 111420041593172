export default Object.freeze([
	{
		key: 'G01',
		label: 'Adquisición de mercancías',
	},
	{
		key: 'G03',
		label: 'Gastos en general',
	},
	{
		key: 'I01',
		label: 'Construcciones',
	},
	{
		key: 'I02',
		label: 'Mobiliario y equipo de oficina por inversiones',
	},
	{
		key: 'I03',
		label: 'Equipo de transporte',
	},
	{
		key: 'I04',
		label: 'Equipo de cómputo',
	},
	{
		key: 'I08',
		label: 'Otra maquinaria y equipo',
	},
	{
		key: 'D04',
		label: 'Donativos',
	},
	{
		key: 'CP01',
		label: 'Pagos',
	},
	{
		key: 'CN01',
		label: 'Nómina',
	},
])
